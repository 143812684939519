@font-face {
    font-family: 'NoirPro';
    src: url('NoirPro-Bold.eot');
    src: local('☞Noir Pro Bold'), local('NoirPro-Bold'),
        url('NoirPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('NoirPro-Bold.woff2') format('woff2'),
        url('NoirPro-Bold.woff') format('woff'),
        url('NoirPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'NoirPro';
    src: url('NoirPro-BoldItalic.eot');
    src: local('☞Noir Pro Bold Italic'), local('NoirPro-BoldItalic'),
        url('NoirPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('NoirPro-BoldItalic.woff2') format('woff2'),
        url('NoirPro-BoldItalic.woff') format('woff'),
        url('NoirPro-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'NoirPro';
    src: url('NoirPro-SemiBoldItalic.eot');
    src: local('☞Noir Pro Semi Bold Italic'), local('NoirPro-SemiBoldItalic'),
        url('NoirPro-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('NoirPro-SemiBoldItalic.woff2') format('woff2'),
        url('NoirPro-SemiBoldItalic.woff') format('woff'),
        url('NoirPro-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'NoirPro';
    src: url('NoirPro-LightItalic.eot');
    src: local('☞Noir Pro Light Italic'), local('NoirPro-LightItalic'),
        url('NoirPro-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('NoirPro-LightItalic.woff2') format('woff2'),
        url('NoirPro-LightItalic.woff') format('woff'),
        url('NoirPro-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'NoirPro';
    src: url('NoirPro-Heavy.eot');
    src: local('☞Noir Pro Heavy'), local('NoirPro-Heavy'),
        url('NoirPro-Heavy.eot?#iefix') format('embedded-opentype'),
        url('NoirPro-Heavy.woff2') format('woff2'),
        url('NoirPro-Heavy.woff') format('woff'),
        url('NoirPro-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'NoirPro';
    src: url('NoirPro-Italic.eot');
    src: local('☞Noir Pro Italic'), local('NoirPro-Italic'),
        url('NoirPro-Italic.eot?#iefix') format('embedded-opentype'),
        url('NoirPro-Italic.woff2') format('woff2'),
        url('NoirPro-Italic.woff') format('woff'),
        url('NoirPro-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'NoirPro';
    src: url('NoirPro-Medium.eot');
    src: local('☞Noir Pro Medium'), local('NoirPro-Medium'),
        url('NoirPro-Medium.eot?#iefix') format('embedded-opentype'),
        url('NoirPro-Medium.woff2') format('woff2'),
        url('NoirPro-Medium.woff') format('woff'),
        url('NoirPro-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'NoirPro';
    src: url('NoirPro-Regular.eot');
    src: local('☞Noir Pro'), local('NoirPro-Regular'),
        url('NoirPro-Regular.eot?#iefix') format('embedded-opentype'),
        url('NoirPro-Regular.woff2') format('woff2'),
        url('NoirPro-Regular.woff') format('woff'),
        url('NoirPro-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'NoirPro';
    src: url('NoirPro-Light.eot');
    src: local('☞Noir Pro Light'), local('NoirPro-Light'),
        url('NoirPro-Light.eot?#iefix') format('embedded-opentype'),
        url('NoirPro-Light.woff2') format('woff2'),
        url('NoirPro-Light.woff') format('woff'),
        url('NoirPro-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'NoirPro';
    src: url('NoirPro-HeavyItalic.eot');
    src: local('☞Noir Pro Heavy Italic'), local('NoirPro-HeavyItalic'),
        url('NoirPro-HeavyItalic.eot?#iefix') format('embedded-opentype'),
        url('NoirPro-HeavyItalic.woff2') format('woff2'),
        url('NoirPro-HeavyItalic.woff') format('woff'),
        url('NoirPro-HeavyItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'NoirPro';
    src: url('NoirPro-MediumItalic.eot');
    src: local('☞Noir Pro Medium Italic'), local('NoirPro-MediumItalic'),
        url('NoirPro-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('NoirPro-MediumItalic.woff2') format('woff2'),
        url('NoirPro-MediumItalic.woff') format('woff'),
        url('NoirPro-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

